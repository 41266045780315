// Notice messages
.notice
  border 1px solid
  border-radius 1rem
  clearfix()
  flex-center()
  margin 0 auto 1rem
  padding 1rem
  width 90%

  &.success
    background-color green-lighten-3
    border-color green-lighten-1

  &.failure
    background-color red-lighten-3
    border-color red-lighten-1

  i
    margin-right 1rem

  p
    margin 0
