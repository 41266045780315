//-- Typograph used throughout the site ----------------------------------------
// Based on Bootstrap v3.3.6 (http://getbootstrap.com)
html, body
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  font-size font-size-root

h1, h2, h3, h4, h5, h6
  font-family font-family-sans-serif
  word-spacing 0.1em

h1
  font-size font-size-h1
  margin 0.9em 0

h2
  font-size font-size-h2
  margin 0.83em 0

h3
  font-size font-size-h3
  margin 0.75em 0

h4
  font-size font-size-h4
  margin 0.5em 0

h5
  font-size font-size-h5
  margin .5em 0

h6
  font-size font-size-h6
  margin .5em 0

p
  color grey-darken-4
  font-family font-family-sans-serif
  font-size font-size-base
  line-height line-height
  margin 1em 0

// Emphasis
small, .small
  font-size 80%
  font-weight normal

mark, .mark
  background-color state-warning-bg
  padding .2em

// Blockquotes
blockquote, .blockquote
  border-left .25rem solid blockquote-border-color
  font-size 90%

  footer
    color blockquote-small-color

    &::before
      content '\2014 \00A0'

  &.blockquote-reverse
    border-left 0
    border-right .25rem solid blockquote-border-color
    text-align right

    footer
      &::before
        content ''

      &::after
        content '\00A0 \2014'

// Description lists
dl
  dt
    font-weight bold

  dd
    margin-left 0

// Unordered and ordered lists
ul, ol
  margin 1em 0
  padding-left 1.5em

  li
    margin-bottom 0.5em

// Inline and block code styles
code, kbd, pre, samp
  font-family font-family-monospace

code
  background-color code-bg
  border-radius .2rem
  color code-color
  display inline-block
  font-size 90%
  margin .1rem 0
  padding .05rem .2rem

kbd
  background-color kbd-bg
  border-radius .2rem
  box-shadow inset 0 -.1rem 0 rgba(0,0,0,.25)
  color kbd-color
  font-size 90%
  padding .2rem .4rem

pre
  background-color grey-lighten-4
  border 1px solid grey-base
  border-radius .5em
  color pre-color
  display block
  font-size 90%
  line-height line-height
  margin 0 0 1rem
  padding .5rem

  code
    background-color transparent
    border-radius 0
    color inherit
    font-size inherit
    padding 0

  &.pre-scrollable
    max-height pre-scrollable-max-height
    overflow-y scroll