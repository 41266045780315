//-- The hero card on the landing page -----------------------------------------
#hero
  overflow hidden
  text-align center

  .wrapper
    margin 0 auto
    max-width 800px

  .logo
    margin 2em auto

  .title
    margin 2em auto

    +below('s')
      margin-bottom 0

    h1
      border-top 1px solid color-text-main
      border-bottom 1px solid color-text-main
      display inline-block
      font-size 2.8em
      font-weight normal
      margin 0
      padding 0.2em 0
      text-transform uppercase

      +below('l')
        font-size 2em

      +below('s')
        font-size 1.2em
        padding 0.4em 0

    h2
      font-size 1.5em

      +below('l')
        font-size 1.3em

      +below('s')
        font-size 1em
        margin-bottom 0

  .hero-vector
    margin-top 1em