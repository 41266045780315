//-- Privacy Page ----------------------------------------------------------------
.privacy
  @extend .blue.lighten-2

  #intro
    .wrapper
      .gfx
        text-align center
        img
          width 60%

  .page-section
    @extend .blue.lighten-5