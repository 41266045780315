//-- List groups ---------------------------------------------------------------
// Based on Bootstrap v3.3.6 (http://getbootstrap.com)
.list-group
  border-radius list-group-border-radius
  margin-bottom 1em
  padding-left 0
  @extend .z-depth-1

// Individual list items
//
// Use on `li`s or `div`s within the `.list-group` parent.
.list-group-item
  background-color list-group-bg-color
  border 1px solid list-group-border-color
  display block
  padding 0.5rem 1rem
  // Place the border on the list items and negative margin up for better styling
  margin-bottom -1px

  &:first-child
    border-top-radius(list-group-border-radius)

  &:last-child
    border-bottom-radius(list-group-border-radius)
    margin-bottom 0

// Interactive list items
//
// Use anchor or button elements instead of `li`s or `div`s to create interactive items.
// Includes an extra `.active` modifier class for showing selected items.
a.list-group-item,
button.list-group-item
  color list-group-link-color
  text-decoration none

  .list-group-item-heading
    color list-group-link-heading-color

  // Hover state
  &:hover,
  &:focus
    background-color list-group-hover-bg-color
    color list-group-link-hover-color
    text-decoration none

button.list-group-item
  text-align left
  width 100%

.list-group-item
  // Disabled state
  &.disabled,
  &.disabled:hover,
  &.disabled:focus
    background-color list-group-disabled-bg-color
    color list-group-disabled-color
    cursor not-allowed

    // Force color to inherit for custom content
    .list-group-item-heading
      color inherit

    .list-group-item-text
      color list-group-disabled-text-color

  // Active class on item itself, not parent
  &.active,
  &.active:hover,
  &.active:focus
    background-color list-group-active-bg-color
    border-color list-group-active-border
    color list-group-active-color
    z-index 2 // Place active items above their siblings for proper border styling

    // Force color to inherit for custom content
    .list-group-item-heading,
    .list-group-item-heading > small,
    .list-group-item-heading > .small
      color inherit

    .list-group-item-text
      color list-group-active-text-color

// Contextual variants
//
// Add modifier classes to change text and background color on individual items.
// Organizationally, this must come after the `:hover` states.
list-group-item-variant(success, state-success-bg, state-success-text)
list-group-item-variant(info, state-info-bg, state-info-text)
list-group-item-variant(warning, state-warning-bg, state-warning-text)
list-group-item-variant(danger, state-danger-bg, state-danger-text)

// Custom content options
//
// Extra classes for creating well-formatted content within `.list-group-item`s.
.list-group-item-heading
  margin-top 0
  margin-bottom 0.5em

.list-group-item-text
  line-height 1.3
  margin-bottom 0
