// The cover elements taking up the entire screen
.cover
  background rgba(0,0,0,0.2)
  display none
  height 100vh
  left 0
  position fixed
  text-align center
  top 0
  width 100%
  z-index 10

  .wrapper
    @extend .flex-center
    height 100vh
    width 100%

  .loader
    @extend .card-panel
    background-color rgba(255,255,255,0.95)
    @extend .z-depth-4
    max-width 500px
    width 90%
