//-- Enhanced inputs -----------------------------------------------------------
.with-icon
  position relative

  i
    font-size 1em
    left input-padding-x
    position absolute
    top input-padding-y * 1.5

  input, textarea, select
    padding-left 2.5em

  select
    height 41px
    text-indent 2em

    +below('s')
      text-indent 0