//-- A feature is some text and a graphic that is typically used as a header. --
.feature
  line-height 0

  &.gfx-right,
  &.gfx-left
    .wrapper, .wrapper-fluid
      clearfix()
      flex-center()

    .gfx
      col(1/2)
      text-align center

      +below('s')
        col(1)
        float none
        margin-bottom 1em

      img
        width auto

    .description
      col(1/2)

      +below('s')
        col(1)
        float none

    +below('s')
      // This inverts the order of the DOM elements so the gfx appears above the
      // description.
      .wrapper
        box-orient vertical
        display -webkit-box

        .gfx
          box-ordinal-group 1

        .description
          box-ordinal-group 2

  &.gfx-top,
  &.gfx-bottom
    .gfx
      img
        margin 0 auto
        width 50%

    .description
      .title
        text-align center

  .wrapper, .wrapper-fluid
    padding 3em

    +below('s')
      padding 1.5em
      display block
      width 100%

  .gfx
    text-align center

    img
      max-width 500px

  .description
    .title
      font-size 1.9rem
      line-height 1.25em
      margin 0.5em 0
      text-transform uppercase

      +below('l')
        font-size 1.8rem

      +below('l')
        font-size 1.4rem

      .fa
        display block
        font-size 1.5em
        margin-bottom 0.25em

        +below('l')
          font-size 1.4em

      .icon-group
        .fa
          display inline-block
          margin 0 0.25rem

      a
        text-decoration none

  &#ecommerce-fulfillment
    .title, .title a
      color color-ecommerce-fulfillment

  &#website-hosting-management
    .title, .title a
      color color-website-hosting-management

  &#international-exports
    .title, .title a
      color color-international-exports

  &#software-development
    .title, .title a
      color color-software-development