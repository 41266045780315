//-- Custom fonts --------------------------------------------------------------
@font-face
  font-family: 'Capriola'
  src:url('/fonts/Capriola.eot')
  src:url('/fonts/Capriola.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Capriola.ttf') format('truetype'),
      url('/fonts/Capriola.woff') format('woff'),
      url('/fonts/Capriola.svg#logomaker') format('svg')
  font-weight: normal
  font-style: normal
