//-- The landing page ----------------------------------------------------------
.landing-page
  background color-app-primary

  #hero
    h1
      border-color white

    .hero-vector
      bottom -100px
      margin-top -100px
      position relative

      +below('l')
        margin-left 2em
        margin-right 2em

      +below('s')
        bottom -50px
        margin-top -50px

      +below('xs')
        bottom -40px
        margin-top -20px
        margin-right 1em
        margin-left 1em

  .feature
    &.gfx-left
      @extend .blue.lighten-5

    &.gfx-right
      @extend .white