//-- The contact section on the landing page -----------------------------------
.contact
  position relative

  .location-indicator
    background color-app-primary
    border-radius 1em
    color white
    left 50%
    margin-left -150px
    padding 1em
    position absolute
    top 50px
    width 300px

    &:after
      border-top-color color-app-primary
      border-right-color transparent
      border-bottom-color transparent
      border-left-color transparent
      border-style solid
      border-width 15px
      bottom -30px
      content ''
      display block
      left 50%
      margin-left -15px
      position absolute

    .title
      border-bottom 1px solid white
      color white
      font-size 1em
      font-weight normal
      margin 0 1em
      padding 0.5em 0
      text-align center

    .method
      border-bottom 1px solid white
      color white
      margin 0 1em
      padding 1em 0
      text-align center

      &:last-child
        border-bottom none

      a
        display block
        color white
        text-decoration none

      .fa
        font-size 1.5em
        margin-bottom 0.5em

      p
        color white
        font-size 0.8em
        margin 0

        a
          color white
          text-decoration none

#map
  height 600px
  width 100%