//-- The page-section component ------------------------------------------------
.page-section
  padding 2em

  +below('s')
    padding 0.5em

  header
    margin-left auto
    margin-right auto
    width 75%

    > h2
      font-weight normal
      margin 0
      text-align center

      &:after
        border-top-width 2px
        border-bottom-width 1px
        border-color color-app-primary
        border-style solid
        content ''
        display block
        margin 0.75rem auto
        width 80px

    > h3
      font-size 1em
      font-weight normal
      text-align center

  p, ul, ol
    color color-text-light
    font-size 0.9em
    margin-left auto
    margin-right auto
    max-width max-standard-width
    text-align justify

    +below('s')
      text-align left

  // Align those features correctly
  .feature
    .wrapper
      padding-left 0
      padding-right 0