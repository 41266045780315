// The payment response
#payment-response
  display none
  position relative
  z-index 1

  p
    color black

  .notice
    @media print
      display none

  #payment-success, #payment-failure
    display none

.payment-receipt
  @media print
    .title
      margin-bottom 2rem
      width auto

  .parties,
  .transaction-details
    col(1/2, cycle: 2, gutter: 0)

  .transaction-details
    table
      th, td
        padding 0.5rem 0

  .metainfo
    clearfix()
    margin-bottom 2rem

    h3
      font-size 1.1rem
      margin 0

    p
      margin 0

  .merchant
    clearfix()
    margin-bottom 1.5rem

    .icon, .info
      float left

    .icon
      margin-right 1rem

      @media print
        display none

  .line-items
    margin-bottom 2rem

    th, td
      border 1px solid
      padding 0.5rem

    #amount
      max-width 200px
      text-align right
      width 20%

  .form-actions
    margin-top 2rem

    @media print
      display none

    .btn
      background grey-lighten-2 !important