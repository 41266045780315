//-- Terms Page ----------------------------------------------------------------
.terms
  @extend .green.lighten-2

  #intro
    .wrapper
      overflow hidden

      .gfx
        margin-bottom -200px

        +below('s')
          height 250px
          margin-bottom 0
          overflow hidden

  .page-section
    @extend .green.lighten-5