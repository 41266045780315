//-- Payments Page -------------------------------------------------------------
.payments
  @extend .blue.lighten-2

  #main-nav
    @media print
      display none

  // Intro feature
  #intro
    position relative
    z-index 1

    @media print
      display none

    .wrapper, .wrapper-fluid
      display block

      .gfx
        img
          margin-bottom -160px

          +below('s')
            margin-bottom -110px

  // Payment form
  form.wrapper
    position relative
    z-index 2

  // Form styles
  .msg-danger
    color state-danger-text
    font-size 1.5 em

  .msg-success
    color state-success-text

  // Main page content
  .page-section
    @extend .blue.lighten-5

    fieldset
      margin 1em auto
      width 75%

      +below(m)
        width 95%

    .spinner
      animation bounce 2s linear infinite
      border 1em solid grey-lighten-2
      border-radius 50%
      border-top 1em solid #588157
      height 5em
      text-align center
      width 5em

  form.wrapper
    .col-one-half,
    .col-one-third
      +below('s')
        col(1/1)

  // Payment methods
  #cc, #eCheck
    display none

  .card-types
    text-align left

    .fa
      color grey-lighten-1
      font-size 2rem
      margin 0 0.25em
      transition color 0.25s ease-in-out

      &.selected
        color grey-darken-3

  // Grid adjustments
  .col-one-half,
  .col-one-third
    margin-bottom 1rem

  // Form adjustments
  .form-actions
    text-align center

    .btn
      background green-lighten-1
      border-radius 0.5rem
      font-size 1.83rem
      text-transform none

      +below('s')
        font-size 1.5rem

      &:hover
        background green
        border-color green
        color white

  #footer
    @media print
      display none