//-- The Grid. Powered by Jeet -------------------------------------------------
.col-one-half
  col(1/2, cycle: 2)
  margin-bottom 3%

.col-one-third
  col(1/3, cycle: 3)
  margin-bottom 3%

.col-one-fourth
  col(1/4, cycle: 4)
  margin-bottom 3%

.col-one-fifth
  col(1/5, cycle: 5)
  margin-bottom 3%

.col-one-sixth
  col(1/6, cycle: 6)
  margin-bottom 3%

.col-one-seventh
  col(1/7, cycle: 7)
  margin-bottom 3%

.col-one-eighth
  col(1/8, cycle: 8)
  margin-bottom 3%

.col-one-ninth
  col(1/9, cycle: 9)
  margin-bottom 3%

.col-one-tenth
  col(1/10, cycle: 10)
  margin-bottom 3%

.col-one-eleventh
  col(1/11, cycle: 11)
  margin-bottom 3%

.col-one-twelfh
  col(1/12, cycle: 12)
  margin-bottom 3%

[class^='col']
  .feature
    .wrapper
      padding 1em