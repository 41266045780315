//-- Tables, oh the tables -----------------------------------------------------
// Based on Bootstrap v3.3.6 (http://getbootstrap.com)
table, th, td
  border none

table
  display table
  width 100%

  &.bordered > thead > tr,
  &.bordered > tbody > tr
    border-bottom 1px solid table-border-color

  &.striped > tbody
    > tr:nth-child(odd)
      background-color table-striped-color

    > tr > td
      border-radius 0

  &.highlight > tbody > tr
    transition background-color .25s ease

    &:hover
      background-color table-striped-color

  &.centered
    thead tr th, tbody tr td
      text-align center
thead
  border-bottom 1px solid table-border-color

td, th
  border-radius 2px
  display table-cell
  padding 15px 5px
  text-align left
  vertical-align middle

+below('m')
  table.responsive-table
    border-collapse collapse
    border-spacing 0
    display block
    position relative
    width 100%

    td:empty:before
      content '\00a0'

    th, td
      margin 0
      vertical-align top

    th
      text-align left

    thead
      display block
      float left

      tr
        display block
        padding 0 10px 0 0

        th::before
          content "\00a0"

    tbody
      display block
      overflow-x auto
      position relative
      white-space nowrap
      width auto

      tr
        display inline-block
        vertical-align top

    th
      display block
      text-align right

    td
      display block
      min-height 1.25em
      text-align left

    tr
      padding 0 10px

    /* sort out borders */
    thead
      border 0
      border-right 1px solid table-border-color

    &.bordered
      th
        border-bottom 0
        border-left 0

      td
        border-left 0
        border-right 0
        border-bottom 0

      tr
        border 0

      tbody tr
        border-right 1px solid table-border-color
