//-- The default theme ---------------------------------------------------------
html, body
  animation fadeIn 1s

// NavBar
#main-nav
  background white
  border-radius 0
  height 90px
  margin-bottom 0

  .wrapper, .wrapper-fluid
    height 100%

  .navbar-brand
    display block
    height auto

    +below('s')
      width 100%

    img
      width 200px

      +below('s')
        margin 0 auto

  .navbar-right
    align-items center
    display flex
    height 90px